body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item-list,
.jsonData {
  max-height: 80vh;
  overflow-y: auto;
}

.active {
  cursor: default;
}

.form-check-input,
.card-header-tabs,
.form-select,
.list-group-item-action,
option {
  cursor: pointer;
}

.code {
  font-family: monospace;
  white-space: pre-wrap;
  white-space: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.code-box-note {
  padding-top: 15px;
  font-style: italic;
  white-space: pre;
}

.encoded-data td {
  padding: 3px;
  vertical-align: top;
  border: none;
  font-size: 1rem;
}

.encoded-data td:first-child {
  width: 70px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-column {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.logo {
  color: #fff;
  text-shadow: 2px 2px 7px black;
}

.user-name {
  margin-right: 17px;
  color: #42454E;
  font-size: 14px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login {
  min-width: 350px;
  width: 25%;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 28px;
  box-shadow: 0px 5px 15px rgba(185, 185, 202, 0.5);
}

.ns-select div {
  padding-left: 20px;
}

.offline {
  text-transform: uppercase;
  color: #42454E;
}

.send-btn {
  color: #ffffff;
  padding-left: 45px !important;
  background-color: #59CAFA;
  background-image: url("./images/send.png");
  background-size: 15px;
  background-position: 20px center;
  background-repeat: no-repeat;
  margin-top: 7px !important;
}

.settings-btn {
  position: absolute;
  top: 80px;
  border-radius: 0 20px 20px 0;
  color: #ffffff;
  padding: 35px 7px 15px;
  max-height: 40%;
  writing-mode: vertical-rl;
  background-color: #59CAFA;
  background-image: url("./images/settings.png");
  background-size: 15px;
  background-position: center 15px;
  background-repeat: no-repeat;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.settings-arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 75px;
  right: 0;
  margin-right: -10px;
  background: url("./images/arrow-1.png") center no-repeat #59CAFA;
  box-shadow: 1px 1px 4px black;
}

.settings-btn:hover,
.settings-arrow:hover {
  background-color: #2CACE2;
  cursor: pointer;
}

.box {
  border: 1px solid #F4F6FA;
  border-radius: 15px;
  padding: 16px;
}

.parameters-table td {
  padding: 10px 14px !important;
}

.parameters-table td .MuiOutlinedInput-root {
  margin-bottom: 0;
}

.parameters-table .collapse-cell {
  padding: 0 !important;
  border: none;
}

.tooltip-icon {
  height: 18px;
  margin-top: 10px;
  margin-left: 10px;
}

.expand-icon {
  position: absolute !important;
  top: 10px;
  right: 0;
  padding: 20px !important
}

.categories-select {
  width: 40%;
  height: 45px;
}

.categories-select div[role='button'] {
  padding-left: 20px;
}

.device-name {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.device-model {
  overflow: hidden;
  margin-left: 5px;
  cursor: inherit !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #F4F6FA;
}

::-webkit-scrollbar-thumb {
  background-color: #59CAFA;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2CACE2;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}